<template>
  <el-container style="min-height: 100vh; border: 1px solid #eee">
    <el-header>
      <p class="title">Dot Fire Manage</p>
      <div class="right">
        <el-dropdown>
          <img class="img" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
          <i class="el-icon-arrow-down" />
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeDialogVisible = true">更改密码</el-dropdown-item>
              <el-dropdown-item @click="seeDialogVisible = true">查看AK/SK</el-dropdown-item>
              <el-dropdown-item @click="dialogVisible = true">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246); text-align: left">
        <el-menu default-active="0" router v-for="(item, index) in navList" :key="index">
          <el-sub-menu :index="index">
            <template #title>
              <i :class="item.icon"></i>
              {{ item.name }}
            </template>
            <el-menu-item-group v-for="(childrenItem, childrenIndex) in item.children" :key="childrenIndex">
              <el-menu-item :index="childrenItem.route">{{ childrenItem.name }}</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- 路由出口 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
  <!-- 退出登录 -->
  <el-dialog title="提示" v-model="dialogVisible" center width="30%">
    <i class="el-icon-warning" />
    <span class="tip-text">确定要退出登录</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exit">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 修改密码 -->
  <el-dialog title="修改密码" v-model="changeDialogVisible" center width="30%">
    <el-form>
      <el-form-item label="旧密码" label-width="120px">
        <el-input v-model="password" type="password" />
      </el-form-item>
      <el-form-item label="新密码" label-width="120px">
        <el-input v-model="newPassword" type="password" />
      </el-form-item>
      <el-form-item label="再次确认新密码" label-width="120px">
        <el-input v-model="confirmNewPassword" type="password" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="changeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 查看 -->
  <el-dialog title="查看AK/SK" v-model="seeDialogVisible" center width="30%">
    <el-form>
      <el-form-item label="查看AK" label-width="120px">
        <el-input v-model="accessKey" readonly />
      </el-form-item>
      <el-form-item label="查看SK" label-width="120px">
        <el-input v-model="accessSecret" readonly />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="seeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="seeDialogVisible = false">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, ref } from 'vue'
import { NAV_LIST } from '@/config/constEnum'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Layout',
  setup() {
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const changeDialogVisible = ref(false)
    const navList = NAV_LIST
    const router = useRouter()

    const accessKey = ref('')
    const password = ref('')
    const newPassword = ref('')
    const accessSecret = ref('')
    const confirmNewPassword = ref('')

    const exit = () => {
      dialogVisible.value = false
      localStorage.setItem('isLogin', false)
      ElMessage.success('退出成功')
      router.push({
        name: 'Login'
      })
    }

    const changePassword = () => {
      if (!password.value) return ElMessage.warning('请填写旧密码')
      if (!newPassword.value) return ElMessage.warning('请填写新密码')
      if (newPassword.value !== confirmNewPassword.value) return ElMessage.warning('新密码和确认新密码不一样！')
      const reqData = {
        old_password: password.value,
        new_password: newPassword.value
      }
      axios
        .post('/-/user/modify/pass', reqData)
        .then(() => {
          changeDialogVisible.value = false
          ElMessage.success('修改密码成功')
          exit()
        })
        .catch(e => {
          if (e.response.data.err_msg === 'old password error') return ElMessage.warning('旧密码错误')
        })
    }

    const getAk = () => {
      axios.get('/-/user/ak/detail').then(res => {
        const data = res.data.msg
        accessKey.value = data.access_key
        accessSecret.value = data.access_secret
      })
    }

    onMounted(() => {
      getAk()
    })
    return {
      exit,
      navList,
      password,
      accessKey,
      newPassword,
      accessSecret,
      dialogVisible,
      changePassword,
      seeDialogVisible,
      confirmNewPassword,
      changeDialogVisible
    }
  }
})
</script>
<style scoped>
.title {
  color: #fff;
  text-align: left;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  font-weight: bolder;
  margin: 0;
}

.right {
  position: absolute;
  top: 0;
  right: 20px;
}

.img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #42b983;
}

.el-icon-arrow-down {
  font-size: 16px;
  display: inline-block;
  color: #fff;
  vertical-align: middle;
}

.fixed-btn {
  display: inline-block;
  vertical-align: top;
}

.tip-text {
  padding-left: 6px;
  font-size: 16px;
}

.el-header {
  background-color: #474747;
  color: var(--el-text-color-primary);
  line-height: 60px;
}

.el-aside {
  color: var(--el-text-color-primary);
}

.el-menu-item-group >>> .el-menu-item-group__title {
  padding: 0;
}

.el-dialog__body {
  font-size: 16px;
}
</style>
