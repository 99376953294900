import { createApp } from 'vue'
import App from './App.vue'
import router, { setupRouter } from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
;(async () => {
  const app = createApp(App)
  app.use(ElementPlus, {
    size: "medium",
    zIndex: 1,
  })
  // 配置路由
  setupRouter(app)
  // 当路由加载完渲染页面
  await router.isReady()
  app.mount('#app')
})()
