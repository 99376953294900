<template>
  <div class="login-wrap">
    <el-container class="container">
      <el-main>
        <div class="content">
          <el-row>
            <el-col :span="10">
              <div class="logo-content">
                <h3 class="logo-title">Dot Fire Manage</h3>
                <img class="img" src="../../assets/image/bg.png" />
              </div>
            </el-col>
            <el-col :span="14">
              <el-row justify="center" style="padding-top: 10px; text-align: left">
                <el-col :span="14">
                  <h3 class="login-text">欢迎登录</h3>
                </el-col>
              </el-row>
              <el-row justify="center" style="padding-top: 10px">
                <el-col :span="14">
                  <el-input v-model="username" placeholder="请输入账号" />
                </el-col>
              </el-row>
              <el-row justify="center" style="padding-top: 10px">
                <el-col :span="14">
                  <el-input v-model="password" type="password" placeholder="请输入密码" />
                </el-col>
              </el-row>
              <el-row justify="center" style="padding-top: 20px">
                <el-col :span="14">
                  <el-button type="primary" style="width: 100%" @click="login">登录</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter()
    const username = ref('')
    const password = ref('')

    const login = () => {
      const reqData = {
        user_name: username.value,
        password: password.value
      }
      if (!username.value) return ElMessage.warning('请输入账号')
      if (!password.value) return ElMessage.warning('请输入密码')

      axios
        .post('/-/admin_sms/auth/login', reqData)
        .then(() => {
          // 设置登录信息
          localStorage.setItem('isLogin', true)
          ElMessage.success('登录成功')
          router.push({
            path: '/analysis',
            name: 'analysis'
          })
        })
        .catch(() => {
          ElMessage.error('登录失败')
        })
    }

    return {
      login,
      username,
      password
    }
  }
})
</script>
<style lang="less">
.login-wrap {
  min-height: 100vh;
  background-color: #474747;

  .container {
    height: 100vh;

    .content {
      position: fixed;
      padding: 100px 0;
      width: 800px;
      height: 421px;
      top: 50%;
      left: 50%;
      border-radius: 30px;
      border: 1px solid #333;
      background-color: #f6f5fd;
      margin: -210px -400px 0;
      box-shadow: 0 10px 42px rgba(0, 0, 0, 0.6);

      .logo-content {
        padding-left: 60px;

        .logo-title {
          position: absolute;
          top: -80px;
          left: 30px;
          font-size: 26px;
          color: #545c64;
          padding-bottom: 10px;
        }

        .img {
          position: absolute;
          left: 8px;
          width: 500px;
        }
      }

      .login-text {
        font-size: 26px;
      }
    }
  }
}
</style>
